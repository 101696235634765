@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: #070918; /* Sfondo blu scuro */
  color: #d4d4d4; /* Testo chiaro */
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 80px 0 0;
}

.home-page,
.blog-page {
  padding: 20px;
}

h1 {
  color: #fff;
}